import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PushNotificationComponent implements OnInit {
  checked = false;

  constructor(
    private readonly matDialogRef: MatDialogRef<PushNotificationComponent>
  ) {}

  ngOnInit() {}

  send(title: string, message: string) {
    this.matDialogRef.close({ title, message, checked: this.checked });
  }

  close(): void {
    this.matDialogRef.close();
  }
}
