import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectAuthProfile } from '../../features/auth/store/auth.selectors';
import { State } from '../../reducers';
import { go } from '../store/router.actions';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(public router: Router, private store: Store<State>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(selectAuthProfile).pipe(
      filter((e) => !!e),
      map((authProfile) => {
        if (!authProfile.profile.role) {
          this.store.dispatch(go({ path: ['auth', 'login'] }));
          return false;
        }

        return this.checkToken(route, authProfile.profile.role);
      })
    );
  }

  private checkToken(route: ActivatedRouteSnapshot, role: string): boolean {
    return route.data['roles'].includes(role);
  }
}
