import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tokenName',
})
export class TokenNamePipe implements PipeTransform {
  transform(name: string) {
    if (!name) return '';

    switch (name) {
      case 'Convex':
        return 'LP';
      case 'Yearn Vault USDC':
        return 'yvUSDC';
      default:
        return '';
    }
  }
}
