import { PermissionType } from './permission.type';
import { Injectable } from '@angular/core';
import { PermissionsFactory } from './permissions/factory.permissions';
import { Resource } from './resource.enum';
import { PermissionBase } from './permissions/base.permissions';

@Injectable({ providedIn: 'root' })
export class PermissionManagerService {
  private permissions: PermissionBase;

  constructor() {}

  isGranted(resource: Resource, permission: PermissionType) {
    this.permissions = PermissionsFactory.getInstance();

    for (const res of this.permissions['permissions']) {
      if (resource === res['resource']) {
        for (const perm of res['permissions']) {
          if (perm === permission) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
