import { createAction, props } from '@ngrx/store';
import { User, UserPagination } from '../models/user.type';

// Load users
export const getUsersPage = createAction(
  '[Users] Get users page',
  props<{
    filter: string;
    sortDirection: 'ASC' | 'DESC';
    pageIndex: number;
    pageSize: number;
  }>()
);

export const setPaginator = createAction(
  '[Users] Set paginator config',
  props<{
    filter: string;
    sortDirection: 'ASC' | 'DESC';
    pageIndex: number;
    pageSize: number;
  }>()
);

export const clearUsersTable = createAction('[Users] Clear users table');

export const getUsersPageSuccess = createAction(
  '[Users] Get users page success',
  props<{ page: UserPagination }>()
);

export const getUsersPageError = createAction(
  '[Users] Get users page error',
  props<{ message: string }>()
);

// Update user
export const updateUserStatus = createAction(
  '[Selected user / Users] Update user status',
  props<{ user: User }>()
);

export const updateUserStatusSuccess = createAction(
  '[Selected user / Users] Update user status success',
  props<{ user: User }>()
);

export const updateUserStatusError = createAction(
  '[Selected user / Users] Update user status error',
  props<{ message: string }>()
);

// Notify all
export const notifyAllUsers = createAction(
  '[Selected users] Notify all users',
  props<{ title: string; message: string; solarisOnly: boolean }>()
);

export const notifyAllUsersSuccess = createAction(
  '[Selected users] Notify all users success'
);

// Module left
export const usersModuleClosed = createAction('[Users] Users module left');
