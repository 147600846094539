import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Role } from '../../../role-manager/role.enum';
import { RouteInfo } from '../../../models/router-info.type';
import { State } from '../../../../reducers';
import { getRole } from '../../../functions/getRole.function';
import { ROUTES } from '../../../classes/route-info';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  @Output() closeSidenav = new EventEmitter<void>();
  role: string;
  public menuItems: any[];

  constructor(
    public translate: TranslateService,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.role = getRole()
    this.menuItems = ROUTES.filter((menuItem) => menuItem);

    this.translate.onLangChange.subscribe(() => this.translateMenuItems());
    this.translateMenuItems();
  }


  changeLang(e) {
    this.translate.use(e);
  }

  translateMenuItems() {
    this.menuItems.forEach((item) => this.translateMenuItem(item));
  }

  translateMenuItem(item) {
    if (item) {
      item.title = this.translate.instant(item.data);
    }
  }

  onClose() {
    this.closeSidenav.emit();
  }

  // TODO
  onLogout() {
    // this.store.dispatch(logout());
  }
}
