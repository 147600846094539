import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';

@Component({
  selector: 'app-delete-page',
  templateUrl: './delete-page.component.html',
  styleUrls: ['./delete-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeletePageComponent implements OnInit {
  @Output() noAction = new EventEmitter();
  @Output() yesAction = new EventEmitter();

  @Input() name: string;

  constructor() {}

  ngOnInit() {}

  onNoClick(): void {
    this.noAction.emit();
  }

  onSubmit() {
    this.yesAction.emit();
  }
}
