import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthActions } from '../../auth/store/action-types';
import { clearUsersTable, getUsersPageError } from './users.actions';
import { UsersService } from '../services/users.service';
import { UserPagination } from '../models/user.type';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers';
import { UsersActions } from './action-types';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UserEffects {
  // load users page
  loadUsersPge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getUsersPage),
      switchMap(({ filter, sortDirection, pageIndex, pageSize }) => {
        return this.usersService
          .loadUsersPage(filter, sortDirection, pageIndex, pageSize)
          .pipe(
            tap(() => this.store.dispatch(clearUsersTable())),
            map((page: UserPagination) => {
              return UsersActions.getUsersPageSuccess({ page });
            }),
            catchError(() =>
              of(getUsersPageError({ message: 'Wrong or expired token' }))
            )
          );
      })
    )
  );

  //   update user status
  updateUserStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.updateUserStatus),
      switchMap(({ user }) => {
        return this.usersService.update(user).pipe(
          map((user) => UsersActions.updateUserStatusSuccess({ user })),
          catchError(() =>
            of(getUsersPageError({ message: 'User status can not be updated' }))
          )
        );
      })
    )
  );

  // notify all users
  notifyAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.notifyAllUsers),
      switchMap(({ title, message, solarisOnly }) => {
        return this.usersService
          .notifyAllUsers(title, message, solarisOnly)
          .pipe(map(() => UsersActions.notifyAllUsersSuccess()));
      })
    )
  );

  // logout
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {})
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly usersService: UsersService,
    private readonly store: Store<State>,
    public toastr: ToastrService
  ) {}
}
