import { Resource } from '../resource.enum';
import { PermissionBase } from './base.permissions';
import { Permission } from '../permission';
import { PermissionType } from '../permission.type';

export class CharityPartnershipOfficerPermission extends PermissionBase {
  constructor() {
    super();
    this.permissions = [
      // WELCOME PAGE
      new Permission(Resource.HOME, [PermissionType.ACCESS]),

      // ASSOCIATIONS' PAGE (TABLE)
      new Permission(Resource.CHARITIES, [
        PermissionType.ACCESS,
        PermissionType.CREATE,
      ]),

      new Permission(Resource.CHARITY_WALLET_AMOUNT, [PermissionType.ACCESS]),

      new Permission(Resource.CHARITY_DETAILS, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
      ]),

      // INFO TAB
      new Permission(Resource.CHARITY_INFO_TAB, [PermissionType.ACCESS]),
      // ACTIONS
      new Permission(Resource.TAX_RECEIPT_ISSUANCE, [PermissionType.ACCESS]),
      new Permission(Resource.TAX_REPORT_GENERATOR, [PermissionType.ACCESS]),
      new Permission(Resource.DONATION_REPORT, [PermissionType.ACCESS]),
      new Permission(Resource.TAX_REPORT_ARCHIVE, [PermissionType.ACCESS]),

      // EXTRAS TAB
      new Permission(Resource.CHARITY_EXTRAS_TAB, [PermissionType.ACCESS]),
      // MANGOPAY TAB
      new Permission(Resource.CHARITY_MANGOPAY_TAB, [PermissionType.ACCESS]),
      // SECTIONS TAB
      new Permission(Resource.CHARITY_SECTIONS_TAB, [PermissionType.ACCESS]),
      // TRANSACTIONS TAB
      new Permission(Resource.CHARITY_TRANSACTIONS_TAB, [
        PermissionType.ACCESS,
      ]),

      // MERCHANTS' PAGE (TABLE)
      new Permission(Resource.MERCHANTS, [PermissionType.ACCESS]),
      new Permission(Resource.MERCHANT_DETAILS, [PermissionType.ACCESS]),
      new Permission(Resource.MERCHANT_INFO_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.MERCHANT_RULES_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.MERCHANT_COVER_TAB, [PermissionType.ACCESS]),
    ];
  }
}
