import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '../models/user.type';
import {
  clearUsersTable,
  getUsersPage,
  getUsersPageSuccess,
  setPaginator,
  updateUserStatusSuccess,
  usersModuleClosed,
} from './users.actions';
import { logout } from '../../auth/store/auth.actions';

export const usersFeatureKey = 'users';

export interface UsersEntityState extends EntityState<User> {
  total?: number;
}

export interface PaginatorState {
  filter: string;
  sortDirection: 'ASC' | 'DESC';
  pageIndex: number;
  pageSize: number;
}

export interface UsersState {
  users: UsersEntityState;
  loaded: boolean;
  loading: boolean;
  paginator: PaginatorState;
}

export const adapterUsers = createEntityAdapter<User>({
  selectId: (user) => user.id,
});

const usersInitialState: UsersEntityState = adapterUsers.getInitialState({
  total: 0,
});

export const paginatorInitialState: PaginatorState = {
  filter: '',
  sortDirection: 'DESC',
  pageIndex: 1,
  pageSize: 20,
};

export const initialUsersState: UsersState = {
  users: usersInitialState,
  loading: false,
  loaded: false,
  paginator: paginatorInitialState,
};

const _usersReducer = createReducer(
  initialUsersState,
  // load users
  on(
    getUsersPage,
    (state: UsersState): UsersState => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    getUsersPageSuccess,
    (state: UsersState, { page }): UsersState => {
      return {
        ...state,
        users: adapterUsers.upsertMany(page.data, {
          ...state.users,
          total: page.total,
        }),
        loaded: true,
        loading: false,
      };
    }
  ),
  on(clearUsersTable, (state: UsersState) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      users: usersInitialState,
    };
  }),
  on(
    setPaginator,
    (
      state: UsersState,
      { filter, sortDirection, pageIndex, pageSize }
    ): UsersState => {
      return {
        ...state,
        paginator: {
          filter,
          sortDirection,
          pageIndex,
          pageSize,
        },
      };
    }
  ),
  // update user
  on(
    updateUserStatusSuccess,
    (state: UsersState, { user }): UsersState => {
      return {
        ...state,
        users: adapterUsers.upsertOne(user, { ...state.users }),
      };
    }
  ),
  on(usersModuleClosed, (state: UsersState) => {
    return {
      ...state,
      ...initialUsersState,
    };
  }),

  // logout
  on(logout, (state: UsersState) => {
    return {
      ...state,
      ...initialUsersState,
    };
  })
);

export function usersReducer(state: UsersState | undefined, action: Action) {
  return _usersReducer(state, action);
}
