import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'role',
})
export class RolePipe implements PipeTransform {
  roleExplanation = {
    ADMIN: 'ADMINISTRATOR',
    CS_AGENT: 'CUSTOMER SUPPORT AGENT',
    MARKETING: 'MARKETING SPECIALIST',
    SALES: 'SALES SPECIALIST',
    CHARITY: 'ASSICIATION REPRESENTATIVE',
    CHARITY_PARTNERSHIP_OFFICER: 'CHARITY PARTNERSHIP OFFICER',
    PARTNERSHIP: 'PARTNER REPRESENTATIVE',
  };
  transform(value: string) {
    return this.roleExplanation[value];
  }
}
