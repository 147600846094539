import { createSelector, createFeatureSelector } from '@ngrx/store';
import { adapter, AuthState } from './auth.reducer';

export const selectFeature = createFeatureSelector<AuthState>('auth');

const adapterSelectors = adapter.getSelectors();

export const selectIsAuthenticated = createSelector(
  selectFeature,
  ({ authenticated }) => authenticated
);

export const selectIsLoading = createSelector(
  selectFeature,
  ({ loading }) => loading
);

export const selectCredentialstStatus = createSelector(
  selectFeature,
  ({ credentialsStatus }) => credentialsStatus
);

export const selectAuthProfile = createSelector(
  selectFeature,
  (state) =>
    state.authProfile.entities[Object.keys(state.authProfile.entities)[0]]
);

export const jwToken = createSelector(
  selectFeature,
  (state) =>
    state.authProfile.entities[Object.keys(state.authProfile.entities)[0]]
      .jwtToken
);

export const csrf = createSelector(
  selectFeature,
  (state) =>
    state.authProfile.entities[Object.keys(state.authProfile.entities)[0]].csrf
);

export const role = createSelector(
  selectFeature,
  (state) =>
    state.authProfile.entities[Object.keys(state.authProfile.entities)[0]]
      .profile.role
);
