import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { selectAuthProfile } from '../../features/auth/store/auth.selectors';
import { first, mergeMap } from 'rxjs/operators';
import { AuthProfile } from '../../features/auth/models/user.interface';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store<State>
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthProfile).pipe(
      first(),
      mergeMap((authProfile: AuthProfile) => {
        const authReq = authProfile
        ? req.clone({
          headers: new HttpHeaders({
            Authorization: authProfile.jwtToken,
            'x-csrf-token': authProfile.csrf
          })
        })
        : req
        return next.handle(authReq)
      })

    )
  }
}
