import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnectorComponent } from '../../classes/control-value-accessor-connector';

@Component({
  selector: 'app-custom-form-control-input',
  templateUrl: './custom-form-control-input.component.html',
  styleUrls: ['./custom-form-control-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomFormControlInputComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFormControlInputComponent extends ControlValueAccessorConnectorComponent
implements OnInit {

  constructor(injector: Injector) { 
    super(injector);
  }

  ngOnInit() {
  }

}
