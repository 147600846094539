import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RequestSignin } from '../models/request-signin.type';
import { AuthProfile } from '../models/user.interface';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  signin(credentials: RequestSignin): Observable<{ status: boolean }> {
    return this.httpClient.post<any>(
      `${environment.baseUrl}/admin/signin`,
      credentials
    );
  }

  verify2FA(email: string, token: string): Observable<AuthProfile> {
    return this.httpClient.post<AuthProfile>(
      `${environment.baseUrl}/admin/verify`,
      {
        email,
        token,
      }
    );
  }

  checkKey(key: string): Observable<AuthProfile> {
    return this.httpClient.get<any>(
      `${environment.baseUrl}/admin/signin/check/${key}`
    );
  }
}
