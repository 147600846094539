import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'endToEndPipe'
})
export class EndToEndPipe implements PipeTransform {
  transform(value: string,) {
    switch(true) {
      case value.includes('CANB-WD-EUR'):
        return 'Withdrawal inside Eurezone';
      case value.includes('CANB-WD-NOT-EUR'):
        return 'Withdrawal outside Eurezone';
        case value.includes('CANB-NC'):
        return 'New card';
    } 
  }
}
