import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { CoreModule } from 'core/core.module';

import { ToastrModule } from 'ngx-toastr';

import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';

import { AuthService } from './features/auth/service/auth.service';
import { BottomSheetComponent } from './shared/components/bottom-sheet/bottom-sheet.component';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './shared/layouts/components/header/header.component';
import { SidenavComponent } from './shared/layouts/components/sidenav/sidenav.component';
import { AppRoutingModule } from './app-routing.module';
import { AdminLayoutComponent } from './shared/layouts/containers/admin/admin-layout.component';
import { AuthLayoutComponent } from './shared/layouts/containers/auth/auth-layout.component';
import { MaterialModule } from './features/material/material.module';
import { PushNotificationComponent } from './shared/components/push-notification/push-notification.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  usersFeatureKey,
  usersReducer,
} from './features/users/store/users.reducer';
import { UserEffects } from './features/users/store/users.effects';
import { AuthEffects } from './features/auth/store/auth.effects';
import { RouterEffect } from './core/store/router.effects';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DECLARATIONS = [
  AppComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  SidenavComponent,
  BottomSheetComponent,
  PushNotificationComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  providers: [AuthGuard, RoleGuard, AuthService],
  imports: [
    // vendor
    CoreModule,
    SharedModule,

    // routing
    AppRoutingModule,

    MaterialModule,

    // ngrx
    StoreModule.forFeature(usersFeatureKey, usersReducer),
    EffectsModule.forFeature([UserEffects, AuthEffects, RouterEffect]),

    // Toaster
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
  ],
  entryComponents: [BottomSheetComponent, PushNotificationComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
