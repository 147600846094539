import { Resource } from '../resource.enum';
import { PermissionBase } from './base.permissions';
import { Permission } from '../permission';
import { PermissionType } from '../permission.type';

export class AdminPermission extends PermissionBase {
  constructor() {
    super();
    this.permissions = [
      // WELCOME PAGE
      new Permission(Resource.HOME, [PermissionType.ACCESS]),

      // CLIENTS' PAGE (TABLE)
      new Permission(Resource.CLIENTS, [PermissionType.ACCESS]),

      // CLIENT'S DETAILS
      new Permission(Resource.CLIENTS_DETAILS, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
      ]),

      // INFO TAB
      new Permission(Resource.CLIENT_INFO_TAB, [PermissionType.ACCESS]),

      // INFO SECTION
      new Permission(Resource.CLIENT_INFO_TAB_INFO, [PermissionType.ACCESS]),
      // ADDRESS TAB
      new Permission(Resource.CLIENT_INFO_TAB_ADDRESS, [PermissionType.ACCESS]),
      // MANGOPAY SECTION
      new Permission(Resource.CLIENT_INFO_TAB_MANGOPAY, [
        PermissionType.ACCESS,
      ]),
      // ACTIONS SECTION
      new Permission(Resource.CLIENT_INFO_TAB_ACTIONS, [PermissionType.ACCESS]),

      // ACTIONS
      new Permission(Resource.DELETE_CLIENT, [PermissionType.ACCESS]),
      new Permission(Resource.CHANGE_EMAIL, [PermissionType.ACCESS]),
      new Permission(Resource.NOTIFY_CLIENT, [PermissionType.ACCESS]),
      new Permission(Resource.DONATE_CLIENT, [PermissionType.ACCESS]),
      new Permission(Resource.CLIENT_DONATIONS, [PermissionType.ACCESS]),
      new Permission(Resource.REVOKE_TOKEN, [PermissionType.ACCESS]),
      new Permission(Resource.REFERRAL_ACCOUNTS, [PermissionType.ACCESS]),
      new Permission(Resource.CHANGE_MOBILE_NUMBER, [PermissionType.ACCESS]),
      new Permission(Resource.CARD_ORDER, [PermissionType.ACCESS]),
      new Permission(Resource.CARD_LIMIT, [PermissionType.ACCESS]),
      new Permission(Resource.CLOSE_CANB_BANK_ACCOUNT, [PermissionType.ACCESS]),
      new Permission(Resource.SOLARIS_TRANSFER, [PermissionType.ACCESS]),

      // SOLARIS TAB
      new Permission(Resource.CLIENT_SOLARIS_TAB, [PermissionType.ACCESS]),

      // LIVRET ECO
      new Permission(Resource.LIVRET_ECO, [PermissionType.ACCESS]),
      new Permission(Resource.TOKENS, [PermissionType.CREATE]),
      new Permission(Resource.ORDERS, [PermissionType.ACCESS]),

      // PENDING MATCHINGS
      new Permission(Resource.CLIENT_PENDING_MATCHINGS_TAB, [
        PermissionType.ACCESS,
      ]),
      // PERSON
      new Permission(Resource.CLIENT_SOLARIS_PERSON, [PermissionType.ACCESS]),
      // ONBOARDING SECTIONS
      new Permission(Resource.CLIENT_SOLARIS_ONBOARDING, [
        PermissionType.ACCESS,
      ]),
      // ACCOUNT
      new Permission(Resource.CLIENT_SOLARIS_ACCOUNT, [PermissionType.ACCESS]),
      // CARD
      new Permission(Resource.CLIENT_SOLARIS_CARD, [PermissionType.ACCESS]),
      // FEES
      new Permission(Resource.CLIENT_SOLARIS_FEES, [PermissionType.ACCESS]),

      // AZELA TAB
      new Permission(Resource.CLIENT_AZELA_TAB, [PermissionType.ACCESS]),

      // BLOCKCHAIN TAB
      new Permission(Resource.CLIENT_BLOCKCHAIN_TAB, [PermissionType.ACCESS]),

      // TAX RECEIPTS TAB
      new Permission(Resource.CLIENT_TAX_RECEIPTS_TAB, [PermissionType.ACCESS]),

      // LOG TAB
      new Permission(Resource.CLIENT_LOG_TAB, [PermissionType.ACCESS]),

      // COMMENTS TAB
      new Permission(Resource.CLIENT_COMMENTS_TAB, [PermissionType.ACCESS]),

      // ASSOCIATIONS' PAGE (TABLE)
      new Permission(Resource.CHARITIES, [
        PermissionType.ACCESS,
        PermissionType.CREATE,
      ]),

      new Permission(Resource.CHARITY_DETAILS, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
      ]),

      // INFO TAB
      new Permission(Resource.CHARITY_INFO_TAB, [PermissionType.ACCESS]),
      // ACTIONS
      new Permission(Resource.TAX_RECEIPT_ISSUANCE, [PermissionType.ACCESS]),
      new Permission(Resource.TAX_REPORT_GENERATOR, [PermissionType.ACCESS]),
      new Permission(Resource.DONATION_REPORT, [PermissionType.ACCESS]),
      new Permission(Resource.TAX_REPORT_ARCHIVE, [PermissionType.ACCESS]),

      // EXTRAS TAB
      new Permission(Resource.CHARITY_EXTRAS_TAB, [PermissionType.ACCESS]),
      // MANGOPAY TAB
      new Permission(Resource.CHARITY_MANGOPAY_TAB, [PermissionType.ACCESS]),
      // SECTIONS TAB
      new Permission(Resource.CHARITY_SECTIONS_TAB, [PermissionType.ACCESS]),
      // TRANSACTIONS TAB
      new Permission(Resource.CHARITY_TRANSACTIONS_TAB, [
        PermissionType.ACCESS,
      ]),

      // MERCHANTS' PAGE (TABLE)
      new Permission(Resource.MERCHANTS, [
        PermissionType.ACCESS,
        PermissionType.CREATE,
      ]),

      new Permission(Resource.MERCHANT_DETAILS, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
        PermissionType.DELETE,
      ]),

      // TABS
      new Permission(Resource.MERCHANT_INFO_TAB, [PermissionType.ACCESS]),

      new Permission(Resource.MERCHANT_COVER_TAB, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
      ]),

      // ACTIONS
      new Permission(Resource.DELETE_MERCHANT, [PermissionType.ACCESS]),
      new Permission(Resource.REPLANISH_MERCHANT_BALANCE, [
        PermissionType.ACCESS,
      ]),
      new Permission(Resource.MERCHANT_REPORT, [PermissionType.ACCESS]),

      new Permission(Resource.MERCHANT_RULES_TAB, [
        PermissionType.ACCESS,
        PermissionType.DELETE,
      ]),
      new Permission(Resource.MERCHANT_AZELA_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.MERCHANT_AZELA_TAB_EXPORT, [
        PermissionType.ACCESS,
      ]),
      new Permission(Resource.AZELA_TRANSACTION_VALIDATION_MENU, [
        PermissionType.ACCESS,
      ]),
      new Permission(Resource.MERCHANT_BLOCKCHAIN_TAB, [PermissionType.ACCESS]),

      // ADMINS' PAGE (TABLE)
      new Permission(Resource.ADMINS, [
        PermissionType.ACCESS,
        PermissionType.CREATE,
        PermissionType.DELETE,
      ]),

      new Permission(Resource.ADMIN_DETAILS, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
      ]),

      // LOGS' PAGE (TABLE)
      new Permission(Resource.LOG, [PermissionType.ACCESS]),

      // DOCUMENTS
      new Permission(Resource.DOCUMENTS, [
        PermissionType.ACCESS,
        PermissionType.CREATE,
      ]),

      // REPORTS' PAGE (TABLE)
      new Permission(Resource.MENU, [PermissionType.ACCESS]),

      new Permission(Resource.NOTIFICATIONS, [PermissionType.ACCESS]),
      new Permission(Resource.REPORTS, [PermissionType.ACCESS]),

      new Permission(Resource.REPORTS_USERS, [PermissionType.ACCESS]),
      new Permission(Resource.REPORTS_CHARITIES, [PermissionType.ACCESS]),

      // CHARITY WALLETS
      new Permission(Resource.CHARITIES_WALLETS, [PermissionType.ACCESS]),

      // POT BALANCE
      new Permission(Resource.POT_BALANCE, [PermissionType.ACCESS]),

      // ALL USERS REPORT
      new Permission(Resource.ALL_USERS_REPORTS, [PermissionType.ACCESS]),
      // ALL CARDS REPORT
      new Permission(Resource.CARDS_USAGE, [PermissionType.ACCESS]),
      // KYC
      new Permission(Resource.KYC, [PermissionType.ACCESS]),
      // NEOBAKN
      new Permission(Resource.NEOBANK, [PermissionType.ACCESS]),
      // SIGNUP
      new Permission(Resource.SIGNUP, [PermissionType.ACCESS]),
      // SDD
      new Permission(Resource.SDD, [PermissionType.ACCESS]),
      // CMISSED CLOSED ACCOUNTS
      new Permission(Resource.CLOSED_ACCOUNTS, [PermissionType.ACCESS]),
      // PREMIUM CARDS
      new Permission(Resource.PREMIUM_CARDS, [PermissionType.ACCESS]),
      // DEPOSITS
      new Permission(Resource.ACCOUNT_DEPOSIT, [PermissionType.ACCESS]),
      // SEND PUSH TO ALL
      new Permission(Resource.SEND_PUSH, [PermissionType.ACCESS]),

      // SERVICE AVAILABILITY INFO
      new Permission(Resource.SERVICE_AVAILABILITY, [PermissionType.ACCESS]),

      // WALLET AMOUNTA
      new Permission(Resource.MERCHANT_WALLET_AMOUNT, [PermissionType.ACCESS]),
      new Permission(Resource.CHARITY_WALLET_AMOUNT, [PermissionType.ACCESS]),
      new Permission(Resource.BUDGET, [PermissionType.ACCESS]),
    ];
  }
}
