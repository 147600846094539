import * as moment from 'moment';
import { AccountStatus } from '../enum/status.enum';

export class UserPagination {
  data: User[];
  total: number;
}

export class User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  shareEmail: boolean;
  affiliateCode?: string;
  godFather: {
    id?: string;
  };
  budgetId?: number;
  syncDate: string;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  address: Address;
  sponsoredUsers: User[];
  isDeleted: boolean;
  isDeletedAt: Date;
  blockedBecauseOfPaswordReset: boolean;
  compteEco: SavingsAccountRaw;
}

export class Address {
  id: string;
  cp: string;
  city: string;
  address1: string;
  address2: string;
}

export class SavingsAccountRaw {
  id: string;
  userId: string;
  status: AccountStatus;
}

export function compareUsers(u1: User, u2: User) {
  if (moment(u2.createdAt).isAfter(moment(u1.createdAt))) return 1;
  if (moment(u2.createdAt).isBefore(moment(u1.createdAt))) return -1;
  return 0;
}
