import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { logout } from '../../../../features/auth/store/auth.actions';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { BottomSheetComponent } from '../../../components/bottom-sheet/bottom-sheet.component';
import { getRole } from '../../../functions/getRole.function';
import { Inject } from '@angular/core';
import { take } from 'rxjs/operators';
import { State } from '../../../../reducers';
import { WINDOW } from '../../../../core/tokens/window';
import { ROUTES } from '../../../classes/route-info';
import { notifyAllUsers } from '../../../../features/users/store/users.actions';
import { Observable } from 'rxjs';
import { PushNotificationComponent } from '../../../components/push-notification/push-notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter<void>();
  role: string;

  public menuItems: any[];

  constructor(
    public readonly translate: TranslateService,
    private readonly store: Store<State>,
    private readonly _bottomSheet: MatBottomSheet,
    @Inject(WINDOW) private readonly window: Window,
    public readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.role = getRole();
  }

  onRefresh() {
    this.window.location.reload();
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

  changeLang(e) {
    this.translate.use(e);
  }

  // Notifications => send push
  sendPushToUSers(): void {
    this.openDialog(PushNotificationComponent, '500px').subscribe((value) => {
      if (!value) return;
      const { title, message, checked } = value;
      this.store.dispatch(
        notifyAllUsers({ title, message, solarisOnly: checked })
      );
    });
  }

  onLogout() {
    this.store.dispatch(logout());
  }

  openBottomSheet(type: 'flag' | 'notification'): void {
    this._bottomSheet.open(BottomSheetComponent, { data: type });
  }

  private openDialog(component, width, data?): Observable<any> {
    return this.dialog
      .open(component, {
        width,
        data,
        hasBackdrop: true,
        disableClose: true,
      })
      .afterClosed()
      .pipe(take(1));
  }
}
