import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { authReducer } from '../features/auth/store/auth.reducer';

import * as usersStore from '../features/users/store/users.reducer';
import * as authStore from '../features/auth/store/auth.reducer';
import * as adminsStore from '../features/admins/store/admins.reducer';
import * as livretStore from '../features/livret-eco/store/livret.reducer';

import { localStorageSync } from 'ngrx-store-localstorage';

export interface State {
  router: RouterReducerState;
  auth: authStore.AuthState;
  users: usersStore.UsersState;
  admins: adminsStore.AdminsState;
  livret: livretStore.LivretState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  auth: authReducer,
  users: usersStore.usersReducer,
  admins: adminsStore.adminsReducer,
  livret: livretStore.livretReducer,
};

export const metaReducers: MetaReducer<State>[] = [
  appMetaReducer,
  localStorageSyncReducer,
];

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    rehydrate: true,
    keys: ['auth', 'router', 'users', 'amins', 'livret'],
  })(reducer);
}

export function appMetaReducer(reducer: any) {
  return function newReducer(state: any, action: any) {
    const nextState = state;
    return reducer(nextState, action);
  };
}
