import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { User, UserPagination } from '../models/user.type';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  /* ********** USER ********** */

  loadUsersPage(
    filter: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number
  ): Observable<UserPagination> {
    return this.http.get<any>(
      `${environment.baseUrl}/user/page?page=${pageIndex}&limit=${pageSize}&filter=${filter}&sortDirection=${sortDirection}`
    );
  }

  update(user): Observable<User> {
    return this.http.put<User>(`${environment.baseUrl}/user/edit`, user);
  }

  notifyUser(id, body) {
    return this.http.post<any>(
      `${environment.baseUrl}/user/${id}/notifyUser`,
      body
    );
  }

  notifyAllUsers(title: string, message: string, solarisOnly: boolean) {
    return this.http.post<any>(`${environment.baseUrl}/user/notify-all`, {
      title,
      message,
      solarisOnly,
    });
  }

  /* ********** USER ********** */

  revokeToken(userId: string): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}/admin/revoke-token`, {
      userId,
    });
  }
}
