import { RouteInfo } from '../models/router-info.type';
import { PermissionType } from '../role-manager/permission.type';
import { Resource } from '../role-manager/resource.enum';

export const ROUTES: RouteInfo[] = [
  {
    path: '/home',
    title: 'sidebar.home',
    type: 'link',
    data: 'sidebar.home',
    permissions: [Resource.HOME, PermissionType.ACCESS],
  },
  {
    path: '/clients',
    title: 'sidebar.users',
    type: 'link',
    data: 'sidebar.users',
    permissions: [Resource.CLIENTS, PermissionType.ACCESS],
  },
  {
    path: '/admins',
    title: 'sidebar.admins',
    type: 'link',
    data: 'sidebar.admins',
    permissions: [Resource.ADMINS, PermissionType.ACCESS],
  },
  {
    path: '/compte-eco',
    title: 'sidebar.livret',
    type: 'link',
    data: 'sidebar.livret',
    permissions: [Resource.LIVRET_ECO, PermissionType.ACCESS],
  },
];
