import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { AuthProfile } from '../models/user.interface';
import { AuthActions } from './action-types';

export const authFeatureKey = 'auth';

export interface AuthProfileState extends EntityState<AuthProfile> {}

export interface AuthState {
  loading: boolean,
  loaded: boolean,
  credentialsStatus: boolean,
  authenticated: boolean,
  authError: string,
  authProfile: AuthProfileState
}

export const adapter = createEntityAdapter<AuthProfile>({
  selectId: (admin) => admin.profile.id,
});

export const authInitialState: AuthProfileState = adapter.getInitialState()

export const initialState: AuthState = {
  loading: false,
  loaded: false,
  credentialsStatus: false,
  authenticated: false,
  authError: undefined,
  authProfile: authInitialState
}


export const _authReducer = createReducer(
  initialState,
  on(AuthActions.signin, (state, action): AuthState => {
    return {
      ...state,
      loading: true,
      authError: undefined
    }
  }),
  on(AuthActions.signinSuccess, (state, { status }): AuthState => {
    return {
      ...state,
      loading: false,
      credentialsStatus: status,
      authError: undefined
    }
  }),
  on(AuthActions.signinError, (state, action): AuthState => {
    return {
      ...state,
      loading: false,
      credentialsStatus: false,
      authError: action.message
    }
  }),
  on(AuthActions.verifySuccess, (state, { authProfile }): AuthState => {
    return {
      ...state,
      loading: false,
      loaded: true,
      credentialsStatus: false,
      authenticated: true,
      authError: undefined,
      authProfile: adapter.upsertOne(authProfile, {...state.authProfile})
    }
  }),
  on(AuthActions.verifyError, (state, action): AuthState => {
    return {
      ...state,
      loading: false,
      loaded: false,
      authenticated: false,
      credentialsStatus: false,
      authError: action.message,
      authProfile: authInitialState
    }
  }),
  on(AuthActions.credentialsStatusToFalse, (state: AuthState): AuthState => {
    return {
      ...state,
      credentialsStatus: false
    }
  }),
  // logout
  on(AuthActions.logout, (state): AuthState => {
    return {
      ...state,
      ...initialState
    };
  })
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}
