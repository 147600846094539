import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';
import { selectIsAuthenticated } from '../../../../features/auth/store/auth.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminLayoutComponent implements OnInit {
  url: string;
  location: Location;


  constructor(
    location: Location,
    private readonly store: Store<State>
    ) {
    this.location = location;
  }
  ngOnInit() {}

  // GETTERS

  public get isAuthenticated$(): Observable<boolean> {
    return this.store.select(selectIsAuthenticated)
  }
}
