import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { AuthActions } from "../../auth/store/action-types";
import { Admin } from "../models/admin.type";
import { AdminActions } from "./action-types";

export const adminsFeatureKey = 'admins'

export interface AdminsEntityState extends EntityState<Admin> {
    total?: number
}

export interface AdminsState {
    admins: AdminsEntityState,
    loaded: boolean,
    loading: boolean,
    completed: boolean,
    paginator: {
        filter: string;
        sortDirection: 'ASC' | 'DESC',
        pageIndex: number;
        pageSize: number
    }
}

export const adapterAdmins = createEntityAdapter<Admin>({
    selectId: admin => admin.id
})

const adminsInitialState: AdminsEntityState = adapterAdmins.getInitialState({ total: 0 })

const initialAdminsState: AdminsState = {
    admins: adminsInitialState,
    loaded: false,
    loading: false,
    completed: false,
    paginator: {
        filter: '',
        sortDirection: 'DESC',
        pageIndex: 1,
        pageSize: 20
    }
}

const _adminsReducer = createReducer(
    initialAdminsState,
    // load all
    on(AdminActions.getAdminsPage, (state: AdminsState): AdminsState => {
        return {
            ...state,
            loading: true
        }
    }),
    on(AdminActions.getAdminsPageSuccess, (state: AdminsState, { page }): AdminsState => {
        return {
            ...state,
            loaded: true,
            loading: false,
            admins: adapterAdmins.upsertMany(page.data, { ...state.admins, total: page.total })
        }
    }),
    on(AdminActions.clearAdminsTable, (state: AdminsState): AdminsState => {
        return {
            ...state,
            loading: false,
            loaded: false,
            admins: adminsInitialState
        }
    }),
    on(AdminActions.setPaginator, (state: AdminsState, { filter, sortDirection, pageIndex, pageSize }): AdminsState => {
        return {
            ...state,
            paginator: {
                filter,
                sortDirection,
                pageIndex,
                pageSize
            }
        }
    }),
    on(AdminActions.completedToFalse, (state: AdminsState): AdminsState => {
        return {
            ...state,
            completed: false
        }
    }),
    on(AdminActions.adminsModuleClosed, (state: AdminsState): AdminsState => {
        return {
            ...state,
            ...initialAdminsState
        }
    }),
    // add one
    on(AdminActions.addNewAdmin, (state: AdminsState) => {
        return {
            ...state,
            loading: true,
        }
    }),
    on(AdminActions.addNewAdminSuccess, (state: AdminsState, { admin }) => {
        return {
            ...state,
            loading: false,
            completed: true,
            admins: adapterAdmins.upsertOne(admin, {...state.admins, total: state.admins.total + 1})
        }
    }),
    // edit one
    on(AdminActions.editAdmin, (state: AdminsState): AdminsState => {
        return {
            ...state,
            loading: true,
        }
    }),
    on(AdminActions.editAdminSuccess, (state: AdminsState, { admin }): AdminsState => {
        return {
            ...state,
            loading: false,
            completed: true,
            admins: adapterAdmins.upsertOne(admin, {  ...state.admins })
        }
    }),
    // delete one
    on(AdminActions.deleteAdminSuccess, (state: AdminsState, { id }) => {
        return {
            ...state,
            completed: true,
            admins: adapterAdmins.removeOne(id, { ...state.admins, total: state.admins.total - 1 })
        }
    }),
    // logout
    on(AuthActions.logout, (state: AdminsState): AdminsState => {
        return {
            ...state,
            ...initialAdminsState
        }
    })
)


export function adminsReducer(state: AdminsState | undefined, action: Action) {
    return _adminsReducer(state, action);
  }