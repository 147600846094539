import { createAction, props } from "@ngrx/store";
import { Role } from "../../../shared/enum/role.enum";
import { Admin, AdminPaginator } from "../models/admin.type";

// Load admins
export const getAdminsPage = createAction(
    '[Admins] Get admins page',
    props<{filter: string, sortDirection: 'ASC' | 'DESC', pageIndex: number, pageSize: number}>()
)

export const getAdminsPageSuccess = createAction(
    '[Admins] Get admins page success',
    props<{ page: AdminPaginator }>()
)

// Add new admin

export const addNewAdmin = createAction(
    '[Admins] Add new admin',
    props<{ userName: string, email: string, password: string, role: Role, charityId: string}>()
)

export const addNewAdminSuccess = createAction(
    '[Admins] Add new admin success',
    props<{ admin: Admin}>()
)

// Edit amin
export const editAdmin = createAction(
    '[Admins] Edit admin',
    props<{ id: string, userName: string, email: string, password: string, role: Role, charityId: string}>()
)

export const editAdminSuccess = createAction(
    '[Admins] Edit admin success',
    props<{ admin: Admin}>()
)

// delete admin
export const deleteAdmin = createAction(
    '[Admins] Delete admin',
    props<{ id: string }>()
)

export const deleteAdminSuccess = createAction(
    '[Admins] Delete admin success',
    props<{ id: string }>()
)

// Common
export const setPaginator = createAction(
    '[Admins] Set paginator config',
    props<{filter: string, sortDirection: 'ASC' | 'DESC', pageIndex: number, pageSize: number}>()
)

export const clearAdminsTable = createAction(
    '[Admins] Clear admins table',
)

export const completedToFalse = createAction(
    '[Admins] Set completed to false'
)

// Module left
export const adminsModuleClosed = createAction(
    '[Admins] Admins module left'
)