import { SharedModule } from 'shared/shared.module';
import { NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from '../reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '../features/auth/store/auth.effects';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpResponseInterceptor } from './interceptors/http-response.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterCustomSerializer } from './store/router.reducer';
import { RouterEffect } from './store/router.effects';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';

// Translation loader
export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const PROVIDERS: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: AuthInterceptor,
  },
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: HttpResponseInterceptor,
  },
  {
    provide: RouterStateSerializer,
    useClass: RouterCustomSerializer,
  },
];

@NgModule({
  imports: [
    // vendor
    BrowserModule,
    BrowserAnimationsModule,

    // Shared
    SharedModule,

    // Trnaslate
    HttpClientModule,

    // translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // ngrx
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: RouterCustomSerializer,
    }),
    EffectsModule.forRoot([RouterEffect, AuthEffects]),
  ],
  providers: [...PROVIDERS],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
