import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { logout } from '../../features/auth/store/auth.actions';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    public toastr: ToastrService,
    private readonly store: Store<State>
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          this.notify(evt.status);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.store.dispatch(logout());
        }
        if (error) {
          this.toastr.error(error.error[0], 'Error!');
        }
        return throwError(error);
      })
    );
  }

  private notify(status: number) {
    switch (status) {
      case 201:
        this.toastr.success('Successfully');
        break;
      case 204:
        this.toastr.success('All tokens are rovoked');
        break;
      default:
        break;
    }
  }
}
