import { CustomDestroyComponent } from './../../classes/custom-destroy';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import {
  MatSlideToggle,
  MatSlideToggleChange,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material';
import { FeatureFlagsService } from '../../../core/services/feature-flags.service';
import { FeatureFlag } from '../../models/feature-flag.type';
import { Notification } from '../../models/notification.type';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent extends CustomDestroyComponent
  implements OnInit {
  @ViewChild('statusToggle', { static: true }) statusToggle: MatSlideToggle;

  featureFlags$: Observable<FeatureFlag[]>;
  notifications$: Observable<Notification[]>;

  constructor(
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: 'flag' | 'notification'
  ) {
    super();
  }

  ngOnInit() {
    if (this.data === 'flag') {
      this.getFlags();
    }

    if (this.data === 'notification') {
      this.getNotifications();
    }
  }

  toggleFlag(event: MatSlideToggleChange, id: string) {
    this.featureFlagsService
      .updateFlag({ id, state: event.checked })
      .pipe(take(1))
      .subscribe(() => {
        this.getFlags();
      });
  }

  toggleNotification(
    event: MatSlideToggleChange,
    id: string,
    type: 'email' | 'push' | 'slack'
  ) {
    this.featureFlagsService
      .updateNotification({ id, state: event.checked, type })
      .pipe(take(1))
      .subscribe(() => {
        this.getNotifications();
      });
  }

  private getFlags() {
    this.featureFlags$ = this.featureFlagsService.getFeatureFlags();
    this.cdr.detectChanges();
  }

  private getNotifications() {
    this.notifications$ = this.featureFlagsService.getNotifications();
    this.cdr.detectChanges();
  }
}
