import { Resource } from './../resource.enum';
import { PermissionBase } from './base.permissions';
import { Permission } from '../permission';
import { PermissionType } from '../permission.type';

export class MarketingPermission extends PermissionBase {
  constructor() {
    super();
    this.permissions = [
      // WELCOME PAGE
      new Permission(Resource.HOME, [PermissionType.ACCESS]),

      // ASSOCIATIONS' PAGE (TABLE)
      new Permission(Resource.CHARITIES, [PermissionType.ACCESS]),
      new Permission(Resource.CHARITY_DETAILS, [PermissionType.ACCESS]),
      new Permission(Resource.CHARITY_INFO_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.CHARITY_EXTRAS_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.CHARITY_SECTIONS_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.CHARITY_TRANSACTIONS_TAB, [
        PermissionType.ACCESS,
      ]),

      // MERCHANTS' PAGE (TABLE)
      new Permission(Resource.MERCHANTS, [PermissionType.ACCESS]),

      new Permission(Resource.MERCHANT_DETAILS, [PermissionType.ACCESS]),
      new Permission(Resource.MERCHANT_RULES_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.MERCHANT_COVER_TAB, [PermissionType.ACCESS]),
    ];
  }
}
