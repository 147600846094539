import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CustomDestroyComponent } from '../../classes/custom-destroy';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent extends CustomDestroyComponent
  implements OnInit {
  @Input() tableName: string;
  @Input() filterValue: string;
  @Input() permissions: string[];
  @Input() withAddButton: boolean = false;

  @ViewChild('filter', { static: true }) filter: ElementRef;

  @Output() filterValueEmitter = new EventEmitter();
  @Output() addEvent = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    // server-side search
    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(450),
        distinctUntilChanged(),
        tap(() =>
          this.filterValueEmitter.emit(this.filter.nativeElement.value)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onAdd() {
    this.addEvent.emit();
  }
}
