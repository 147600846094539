import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { AuthActions } from './action-types';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { signin, signinError, signinSuccess, verify, verifySuccess, verifyError } from './auth.actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../reducers';
import { go } from '../../../core/store/router.actions';

@Injectable()
export class AuthEffects {
  // Signin
  signin$ = createEffect(
    () =>
    this.actions$.pipe(
    ofType(signin),
    switchMap(({ credentials }) => {
      return this.authService.signin(credentials).pipe(
        map(({ status }) => {
          return signinSuccess({ status })
        }),
        catchError(() => of(signinError({ message: 'Wrong email or password' })))
      )
    })
  ))

  // verify
  verufy$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(verify),
      switchMap(({ email, token }) => {
        return this.authService.verify2FA(email, token).pipe(
          map((authProfile) => {
            return verifySuccess({authProfile})
          }),
          catchError(() => of(verifyError({ message: 'Wrong or expired token' })))
        )
      })
    )
  )

  verifySuccess$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(AuthActions.verifySuccess),
      tap(() => {
        this.store.dispatch(go({path: ['home']}))
      })
    ),
    { dispatch: false }
  )

  // logout
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.store.dispatch(go({ path: ['auth', 'login'] }))
          
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly store: Store<State>
  ) {}
}
