import { RoleGuard } from './core/guards/role.guard';
import { RouterModule, Routes } from '@angular/router';

import { AdminLayoutComponent } from './shared/layouts/containers/admin/admin-layout.component';
import { AuthLayoutComponent } from './shared/layouts/containers/auth/auth-layout.component';
import { NgModule } from '@angular/core';
import { Role } from './shared/role-manager/role.enum';
import { AuthGuard } from './core/guards/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'home',
        data: {
          roles: [
            Role.ADMIN,
            Role.CHARITY_PARTNERSHIP_OFFICER,
            Role.CS_AGENT,
            Role.MARKETING,
            Role.PARTNERSHIP,
            Role.SALES,
            Role.CHARITY,
          ],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () =>
          import('./features/welcome/welcome.module').then(
            (m) => m.WelcomeModule
          ),
      },
      {
        path: 'clients',
        data: {
          roles: [Role.ADMIN, Role.CS_AGENT],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () =>
          import('./features/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'admins',
        data: {
          roles: [Role.ADMIN],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () =>
          import('./features/admins/admins.module').then((m) => m.AdminsModule),
      },
      {
        path: 'compte-eco',
        data: {
          roles: [Role.ADMIN],
        },
        canActivate: [AuthGuard, RoleGuard],
        loadChildren: () =>
          import('./features/livret-eco/livret-eco.module').then(
            (m) => m.LivretEcoModule
          ),
      },
      {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./features/auth/auth.module').then((m) => m.AuthModule),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
