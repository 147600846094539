import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { selectIsAuthenticated } from '../../features/auth/store/auth.selectors';
import { State } from '../../reducers';
import { go } from '../store/router.actions';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly store: Store<State>,
    ) {
    }

  canActivate(): Observable<boolean> {
    return this.store.select(selectIsAuthenticated).pipe(
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
            this.store.dispatch(go({ path: ['auth', 'login'] }));
          return false
        }

        return isAuthenticated;
      })
    );
  }
}
