import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSlideToggle } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../../features/users/models/user.type';
import { CustomDestroyComponent } from '../../classes/custom-destroy';
import { PermissionManagerService } from '../../role-manager/permission-manager.service';
import { PermissionType } from '../../role-manager/permission.type';
import { Resource } from '../../role-manager/resource.enum';
import { SavingsAccount } from '../../../features/users/models/savings-account.type';

@Component({
  selector: 'app-selected-item-header',
  templateUrl: './selected-item-header.component.html',
  styleUrls: ['./selected-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedItemHeaderComponent extends CustomDestroyComponent
  implements OnInit {
  @Input() item: User;
  @Input() itemType: 'CLIENTS_DETAILS';
  @Input() toggleStatus: boolean;
  @Input() savingsAccount: SavingsAccount;

  @Output() slider = new EventEmitter();
  @Output() logo = new EventEmitter();

  logoPreview: string | ArrayBuffer;

  @ViewChild('statusToggle', { static: false }) statusToggle: MatSlideToggle;

  constructor(
    private cdr: ChangeDetectorRef,
    private readonly permissionManagerService: PermissionManagerService
  ) {
    super();
  }

  ngOnInit() {}

  get isUserBlocked(): boolean {
    return (
      this.itemType === 'CLIENTS_DETAILS' &&
      (this.item as User).blockedBecauseOfPaswordReset
    );
  }

  ngAfterViewInit(): void {
    const isGranted = this.permissionManagerService.isGranted(
      Resource[this.itemType],
      PermissionType.UPDATE
    );

    if (isGranted) {
      this.statusToggle.change
        .pipe(takeUntil(this.destroy$))
        .subscribe((status) => {
          this.slider.emit([this.item, status.checked]);
        });
    }
  }

  async fileChangeEvent(event: Event, action: string) {
    const files = (<HTMLInputElement>event.target).files;
    if (files && files[0]) {
      this.logo.emit(files[0]);
      this.logoPreview = await this.fileToBase64(files[0]);
      this.cdr.detectChanges();
    }
  }

  fileToBase64 = (file) =>
    new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
}
