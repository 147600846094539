import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
  } from '@angular/forms';
  import { ElementRef, Injector, Input, ViewChild, Component, InjectionToken } from '@angular/core';
  
  @Component({
    template: '',
  })
  export class ControlValueAccessorConnectorComponent implements ControlValueAccessor {
    @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective | undefined;
  
    @ViewChild('field', { static: true }) field: ElementRef | undefined;
  
    @Input() readonly formControl: FormControl | undefined;
  
    @Input() readonly formControlName: string | undefined;
  
    @Input() readonly selectValues?: { value: string; key: string }[];
  
    @Input() readonly type = 'text';
  
    @Input() readonly label: string = '';
  
    @Input() readonly labelClass: string = '';
  
    @Input() readonly inputClass: string = '';
  
    @Input() readonly placeholder: string | undefined;
  
    @Input() readonly errorRequiredMsg: string | undefined;
  
    @Input() readonly errorEmailMsg: string | undefined;
  
    constructor(private injector: Injector) {}
  
    registerOnTouched(fn: any): void {
      this.formControlDirective!.valueAccessor!.registerOnTouched(fn);
    }
  
    registerOnChange(fn: any): void {
      this.formControlDirective!.valueAccessor!.registerOnChange(fn);
    }
  
    writeValue(obj: any): void {
      this.formControlDirective!.valueAccessor!.writeValue(obj);
    }
  
    setDisabledState(isDisabled: boolean): void {
      this.formControlDirective!.valueAccessor!.setDisabledState!(isDisabled);
    }
  
    //   GETTERS
  
    get control(): AbstractControl | null | undefined {
      return this.formControl || this.controlContainer!.control.get(this.formControlName);
    }
  
    get controlContainer(): ControlContainer {
      return this.injector.get(ControlContainer);
    }
  }
  