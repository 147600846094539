import { PermissionType } from './permission.type';
import { PermissionManagerService } from './permission-manager.service';
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { Resource } from './resource.enum';

@Directive({
  selector: '[appIsGranted]'
})
export class IsGrantedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionManagerService: PermissionManagerService
  ) {}

  @Input() set appIsGranted(permission: Array<string>) {
    this.isGranted(permission[0] as Resource, permission[1] as PermissionType);
  }

  private isGranted(resource: Resource, permissionType: PermissionType) {
    if (this.permissionManagerService.isGranted(resource, permissionType)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
