import { Pipe, PipeTransform } from '@angular/core';
import { SavingsAccount } from '../../features/livret-eco/types/savings-account.type';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'cleanJson',
  pure: true,
})
export class CleanJsonPipe implements PipeTransform {
  transform(value: SavingsAccount): any {
    const copy = cloneDeep(value);
    delete copy['creationDate'];
    delete copy['createdAt'];
    delete copy['deletedAt'];
    delete copy['id'];
    delete copy['userId'];
    delete copy['lastUpdatedDate'];
    delete copy['interestEarnedYesterday'];
    delete copy['spreadsheetId'];
    delete copy['spreadsheetUrl'];

    for (let i = 0; i < copy.balance.length; i++) {
      const balance = copy.balance[i];

      delete balance['createdAt'];
      delete balance['deletedAt'];
      delete balance['id'];
      delete balance['isAccumulative'];
      delete balance['interestEarnedYesterday'];
      delete balance['accountId'];
      delete balance['creationDate'];
      delete balance['lastUpdatedDate'];
      delete balance['currencyId'];

      for (let j = 0; j < balance.subBalances.length; j++) {
        const subBalance = balance.subBalances[j];

        delete subBalance['createdAt'];
        delete subBalance['deletedAt'];
        delete subBalance['id'];
        delete subBalance['tokenId'];
        delete subBalance['symbol'];
        delete subBalance['interestEarnedYesterday'];
        delete subBalance['balanceId'];
        delete subBalance['creationDate'];
        delete subBalance['creationDateReadable'];
        delete subBalance['lastUpdatedDate'];
      }
    }
    return copy;
  }
}
