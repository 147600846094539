import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';

@Component({
  selector: 'app-save-close-buttons',
  templateUrl: './save-close-buttons.component.html',
  styleUrls: ['./save-close-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveCloseButtonsComponent implements OnInit {
  @Output() submit = new EventEmitter();
  @Output() noClick = new EventEmitter();

  @Input() resource: string;
  @Input() permission: string;

  @Input() isLoading: boolean;

  constructor() {}

  ngOnInit() {}

  onSubmit() {
    this.submit.emit();
  }

  onNoClick() {
    this.noClick.emit();
  }
}
