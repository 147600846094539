
import { createAction, props } from '@ngrx/store';
import { RequestSignin } from '../models/request-signin.type';
import { AuthProfile } from '../models/user.interface';


// Signin
export const signin = createAction(
  '[Signin page/ Auth] Signin',
  props<{ credentials: RequestSignin }>()
)

export const signinSuccess = createAction(
  '[Signin page/ Auth] Signin success',
  props<{ status: boolean }>()
)

export const credentialsStatusToFalse = createAction(
  '[Signin page/ Auth] Credentials status to dalse',
)

export const signinError = createAction(
  '[Signin page/ Auth] Signin error',
  props<{ message: string }>());

// Token cheking
export const verify = createAction(
  '[Signin page/ Auth] Verify token',
  props<{ email: string, token: string }>()
);

export const verifySuccess = createAction(
  '[Signin page/ Auth] Verify token success',
   props<{ authProfile: AuthProfile }>()
);

export const verifyError = createAction(
  '[Signin page/ Auth] Verify token error',
  props<{ message: string }>()
);

// Logout
export const logout = createAction('[Top menu] Logout');

