import { Resource } from './../resource.enum';
import { PermissionBase } from './base.permissions';
import { Permission } from '../permission';
import { PermissionType } from '../permission.type';

export class CharityPermission extends PermissionBase {
  constructor() {
    super();
    this.permissions = [
      // WELCOME PAGE
      new Permission(Resource.HOME, [
        PermissionType.ACCESS,
      ]),

      // ASSOCIATIONS' PAGE (TABLE)
      new Permission(Resource.CHARITIES, [
        PermissionType.ACCESS,
      ]),

      new Permission(Resource.CHARITY_DETAILS, [
        PermissionType.ACCESS,
      ]),

        // INFO TAB
        new Permission(Resource.CHARITY_INFO_TAB, [
          PermissionType.ACCESS,
        ]),

          // EXTRAS TAB
          new Permission(Resource.CHARITY_EXTRAS_TAB, [
            PermissionType.ACCESS,
          ]),
          // SECTIONS TAB
          new Permission(Resource.CHARITY_SECTIONS_TAB, [
            PermissionType.ACCESS,
          ]),
          // TRANSACTIONS TAB
          new Permission(Resource.CHARITY_TRANSACTIONS_TAB, [
            PermissionType.ACCESS,
          ]),
    ];
  }
}
