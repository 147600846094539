import { AccountStatus } from '../enum/status.enum';

export class SavingsAccount {
  id: string;
  userId: string;
  creationDate: number; // unix timestamp
  lastUpdatedDate: number; // unix timestamp
  availableBalance: number;
  actualBalance: number;
  status: AccountStatus;
  address: string;
  spreadsheetId?: string;
  spreadsheetUrl?: string;
}
