import jwt_decode from 'jwt-decode';

export function getRole() {
  const userProfileStr = localStorage.getItem('auth');
  const userProfile = JSON.parse(userProfileStr);

  if ( userProfile.authProfile.ids.length ) {

    const token = userProfile.authProfile.entities[Object.keys(userProfile.authProfile.entities)[0]].jwtToken
    const decoded = jwt_decode(token);
    const role = decoded.roles[0];
    return role;
  }
  return;
}
