import { Directive, ElementRef, Input, NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CustomDestroyComponent } from '../classes/custom-destroy';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[copy]',
})
export class CopyDirective extends CustomDestroyComponent {
  @Input() copy: string;

  constructor(
    private host: ElementRef<HTMLElement>,
    private zone: NgZone,
    public toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.host.nativeElement, 'click')
        .pipe(
          switchMap(() => navigator.clipboard.writeText(this.copy)),
          takeUntil(this.destroy$)
        )
        .subscribe(() => this.toastr.success('Copied!'));
    });
  }
}
