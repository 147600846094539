import { Resource } from './../resource.enum';
import { PermissionBase } from './base.permissions';
import { Permission } from '../permission';
import { PermissionType } from '../permission.type';

export class CSAgentPermission extends PermissionBase {
  constructor() {
    super();
    this.permissions = [
      // WELCOME PAGE
      new Permission(Resource.HOME, [PermissionType.ACCESS]),

      // CLIENTS' PAGE (TABLE)
      new Permission(Resource.CLIENTS, [PermissionType.ACCESS]),
      new Permission(Resource.CLIENTS_DETAILS, [PermissionType.ACCESS]),
      new Permission(Resource.CLIENTS_DETAILS, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
      ]),
      // INFO TAB
      new Permission(Resource.CLIENT_INFO_TAB, [
        PermissionType.ACCESS,
        PermissionType.UPDATE,
      ]),

      // INFO SECTION
      new Permission(Resource.CLIENT_INFO_TAB_INFO, [PermissionType.ACCESS]),
      // ADDRESS TAB
      new Permission(Resource.CLIENT_INFO_TAB_ADDRESS, [PermissionType.ACCESS]),
      // MANGOPAY SECTION
      new Permission(Resource.CLIENT_INFO_TAB_MANGOPAY, [
        PermissionType.ACCESS,
      ]),
      // ACTIONS SECTION
      new Permission(Resource.CLIENT_INFO_TAB_ACTIONS, [PermissionType.ACCESS]),

      // ACTIONS
      // ACTIONS
      new Permission(Resource.DELETE_CLIENT, [PermissionType.ACCESS]),
      new Permission(Resource.NOTIFY_CLIENT, [PermissionType.ACCESS]),
      new Permission(Resource.DONATE_CLIENT, [PermissionType.ACCESS]),
      new Permission(Resource.CLIENT_DONATIONS, [PermissionType.ACCESS]),
      new Permission(Resource.REVOKE_TOKEN, [PermissionType.ACCESS]),
      new Permission(Resource.REFERRAL_ACCOUNTS, [PermissionType.ACCESS]),
      new Permission(Resource.CHANGE_MOBILE_NUMBER, [PermissionType.ACCESS]),
      new Permission(Resource.CARD_ORDER, [PermissionType.ACCESS]),
      new Permission(Resource.CARD_LIMIT, [PermissionType.ACCESS]),
      new Permission(Resource.CLOSE_CANB_BANK_ACCOUNT, [PermissionType.ACCESS]),
      new Permission(Resource.SOLARIS_TRANSFER, [PermissionType.ACCESS]),

      // SOLARIS TAB
      new Permission(Resource.CLIENT_SOLARIS_TAB, [PermissionType.ACCESS]),

      // PERSON
      new Permission(Resource.CLIENT_SOLARIS_PERSON, [PermissionType.ACCESS]),
      // ONBOARDING SECTIONS
      new Permission(Resource.CLIENT_SOLARIS_ONBOARDING, [
        PermissionType.ACCESS,
      ]),
      // ACCOUNT
      new Permission(Resource.CLIENT_SOLARIS_ACCOUNT, [PermissionType.ACCESS]),
      // CARD
      new Permission(Resource.CLIENT_SOLARIS_CARD, [PermissionType.ACCESS]),
      // FEES
      new Permission(Resource.CLIENT_SOLARIS_FEES, [PermissionType.ACCESS]),

      // AZELA TAB
      new Permission(Resource.CLIENT_AZELA_TAB, [PermissionType.ACCESS]),
      new Permission(Resource.AZELA_TRANSACTION_VALIDATION_MENU, [
        PermissionType.ACCESS,
      ]),
      // BLOCKCHAIN TAB
      new Permission(Resource.CLIENT_BLOCKCHAIN_TAB, [PermissionType.ACCESS]),
      // TAX RECEIPTS TAB
      new Permission(Resource.CLIENT_TAX_RECEIPTS_TAB, [PermissionType.ACCESS]),
      // LOG TAB
      new Permission(Resource.CLIENT_LOG_TAB, [PermissionType.ACCESS]),

      // ASSOCIATIONS' PAGE (TABLE)
      new Permission(Resource.CHARITIES, [PermissionType.ACCESS]),

      // ASSOCIATION'S DETAILS
      new Permission(Resource.CHARITY_DETAILS, [PermissionType.ACCESS]),
      new Permission(Resource.TAX_RECEIPT_ISSUANCE, [PermissionType.ACCESS]),

      // INFO TAB
      new Permission(Resource.CHARITY_INFO_TAB, [PermissionType.ACCESS]),

      // EXTRAS TAB
      new Permission(Resource.CHARITY_EXTRAS_TAB, [PermissionType.ACCESS]),

      // SECTIONS TAB
      new Permission(Resource.CHARITY_SECTIONS_TAB, [PermissionType.ACCESS]),
      // TRANSACTIONS TAB
      new Permission(Resource.CHARITY_TRANSACTIONS_TAB, [
        PermissionType.ACCESS,
      ]),

      // MERCHANTS' PAGE (TABLE)
      new Permission(Resource.MERCHANTS, [PermissionType.ACCESS]),

      // CHARITY'S DETAILS
      new Permission(Resource.MERCHANT_DETAILS, [PermissionType.ACCESS]),

      // TABS
      new Permission(Resource.MERCHANT_INFO_TAB, [PermissionType.ACCESS]),

      new Permission(Resource.MERCHANT_AZELA_TAB, [PermissionType.ACCESS]),

      new Permission(Resource.MERCHANT_BLOCKCHAIN_TAB, [PermissionType.ACCESS]),

      new Permission(Resource.MERCHANT_COVER_TAB, [PermissionType.ACCESS]),

      new Permission(Resource.MERCHANT_RULES_TAB, [PermissionType.ACCESS]),
    ];
  }
}
