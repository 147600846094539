import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cashbackType'
})
export class CashbackTypePipe implements PipeTransform {
  transform(value: string, type: string) {
    if (!value) return ''
    switch(type) {
      case 'PERCENTAGE':
        return `${value}%`;
      case 'EUROS':
        return `${value}€`;
    } 
  }
}
