import { PermissionBase } from './base.permissions';
import { getRole } from '../../functions/getRole.function';
import { Role } from '../role.enum';
import { AdminPermission } from './admin.permissions';
import { CSAgentPermission } from './cs-agent.permissions';
import { CharityPermission } from './charity.permissions';
import { MarketingPermission } from './marketing.permissions';
import { SalesPermission } from './sales.permissions';
import { CharityPartnershipOfficerPermission } from './charity-partnership-officer.permissions';
import { PartnershipPermission } from './psrtnership.permissions';

export class PermissionsFactory {
  public static instance: PermissionBase;
  public static role: string;
  private constructor() {}

  public static getInstance() {
    this.role = getRole();
    switch (this.role) {
      case Role.ADMIN:
        this.instance = new AdminPermission();
        break;
      case Role.CS_AGENT:
        this.instance = new CSAgentPermission();
        break;
      case Role.CHARITY:
        this.instance = new CharityPermission();
        break;
      case Role.MARKETING:
        this.instance = new MarketingPermission();
        break;
      case Role.SALES:
        this.instance = new SalesPermission();
        break;
      case Role.CHARITY_PARTNERSHIP_OFFICER:
        this.instance = new CharityPartnershipOfficerPermission();
        break;
      case Role.PARTNERSHIP:
        this.instance = new PartnershipPermission();
        break;

    }
    return this.instance;
  }
}
