import { environment } from 'env/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { FeatureFlag } from '../../shared/models/feature-flag.type';
import { Notification } from '../../shared/models/notification.type';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  constructor(private readonly httpClient: HttpClient) {}

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.httpClient
      .get<FeatureFlag[]>(`${environment.baseUrl}/feature-flags/all`)
      .pipe(take(1));
  }

  getNotifications(): Observable<Notification[]> {
    return this.httpClient
      .get<Notification[]>(`${environment.baseUrl}/ns/notifications`)
      .pipe(take(1));
  }

  updateFlag(updateDto: {
    id: string;
    state: boolean;
  }): Observable<FeatureFlag> {
    return this.httpClient
      .patch<FeatureFlag>(
        `${environment.baseUrl}/feature-flags/update`,
        updateDto
      )
      .pipe(take(1));
  }

  updateNotification(updateDto: {
    id: string;
    state: boolean;
    type: 'email' | 'push' | 'slack';
  }): Observable<Notification> {
    return this.httpClient
      .patch<Notification>(`${environment.baseUrl}/ns/notifications`, updateDto)
      .pipe(take(1));
  }
}
