export enum Resource {
  HOME = 'HOME',

  // CLIENTS PAGE
  CLIENTS = 'CLIENTS',
  CLIENTS_DETAILS = 'CLIENTS_DETAILS',
  // CLIENT'S TABS
  CLIENT_INFO_TAB = 'CLIENT_INFO_TAB',
  // CLIENT'S INFO TAB CONTENT
  CLIENT_INFO_TAB_INFO = 'CLIENT_INFO_TAB_INFO',
  CLIENT_INFO_TAB_ADDRESS = 'CLIENT_INFO_TAB_ADDRESS',
  CLIENT_INFO_TAB_MANGOPAY = 'CLIENT_INFO_TAB_MANGOPAY',
  CLIENT_INFO_TAB_ACTIONS = 'CLIENT_INFO_TAB_ACTIONS',
  // ACTIONS
  DELETE_CLIENT = 'DELETE_CLIENT',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  NOTIFY_CLIENT = 'NOTIFY_CLIENT',
  DONATE_CLIENT = 'DONATE_CLIENT',
  CLIENT_DONATIONS = 'CLIENT_DONATIONS',
  REVOKE_TOKEN = 'REVOKE_TOKEN',
  REFERRAL_ACCOUNTS = 'REFERRAL_ACCOUNTS',
  CHANGE_MOBILE_NUMBER = 'CHANGE_MOBILE_NUMBER',
  CARD_ORDER = 'CARD_ORDER',
  CARD_LIMIT = 'CARD_LIMIT',
  CLOSE_CANB_BANK_ACCOUNT = 'CLOSE_CANB_BANK_ACCOUNT',
  SOLARIS_TRANSFER = 'SOLARIS_TRANSFER',

  // SOLARIS TAB
  CLIENT_SOLARIS_TAB = 'CLIENT_SOLARIS_TAB',
  // SOLARIS TAB CONTENT
  CLIENT_SOLARIS_ONBOARDING = 'CLIENT_SOLARIS_ONBOARDING',
  CLIENT_SOLARIS_ACCOUNT = 'CLIENT_SOLARIS_ACCOUNT',
  CLIENT_SOLARIS_PERSON = 'CLIENT_SOLARIS_PERSON',
  CLIENT_SOLARIS_CARD = 'CLIENT_SOLARIS_CARD',
  CLIENT_SOLARIS_FEES = 'CLIENT_SOLARIS_FEES',

  // PENDING MATCHINGS
  CLIENT_PENDING_MATCHINGS_TAB = 'CLIENT_PENDING_MATCHINGS_TAB',

  // LIVRET ECO
  LIVRET_ECO = 'LIVRET_ECO',
  ORDERS = 'ORDERS',
  TOKENS = 'TOKENS',

  // AZELA TRANSACTIONS TAB
  CLIENT_AZELA_TAB = 'CLIENT_AZELA_TAB',
  AZELA_TRANSACTION_VALIDATION_MENU = 'AZELA_TRANSACTION_VALIDATION_MENU',

  // BLOCKCHAIN TRANSACTIONS
  CLIENT_BLOCKCHAIN_TAB = 'CLIENT_BLOCKCHAIN_TAB',

  // TAX RECEIPTS
  CLIENT_TAX_RECEIPTS_TAB = 'CLIENT_TAX_RECEIPTS_TAB',

  // MOBILE ACTIONS LOG
  CLIENT_LOG_TAB = 'CLIENT_LOG_TAB',

  // COMMENTS
  CLIENT_COMMENTS_TAB = 'CLIENT_COMMENTS_TAB',

  // CHARITIES PAGE
  CHARITIES = 'CHARITIES',
  CHARITY_DETAILS = 'CHARITY_DETAILS',

  // CHARITES'TABS
  CHARITY_INFO_TAB = 'CHARITY_INFO_TAB',
  // ACTIONS
  TAX_RECEIPT_ISSUANCE = 'TAX_RECEIPT_ISSUANCE',
  TAX_REPORT_GENERATOR = 'TAX_REPORT_GENERATOR',
  DONATION_REPORT = 'DONATION_REPORT',
  TAX_REPORT_ARCHIVE = 'TAX_REPORT_ARCHIVE',

  CHARITY_EXTRAS_TAB = 'CHARITY_EXTRAS_TAB',
  CHARITY_SECTIONS_TAB = 'CHARITY_SECTIONS_TAB',
  CHARITY_MANGOPAY_TAB = 'CHARITY_MANGOPAY_TAB',
  CHARITY_TRANSACTIONS_TAB = 'CHARITY_TRANSACTIONS_TAB',

  // MERCHANTS' PAGE (TABLE)
  MERCHANTS = 'MERCHANTS',
  MERCHANT_DETAILS = 'MERCHANT_DETAILS',
  // TABS
  MERCHANT_INFO_TAB = 'MERCHANT_INFO_TAB',
  MERCHANT_COVER_TAB = 'MERCHANT_COVER_TAB',
  // ACTIONS
  DELETE_MERCHANT = 'DELETE_MERCHANT',
  REPLANISH_MERCHANT_BALANCE = 'REPLANISH_MERCHANT_BALANCE',
  MERCHANT_REPORT = 'MERCHANT_REPORT',

  MERCHANT_RULES_TAB = 'MERCHANT_RULES_TAB',
  MERCHANT_AZELA_TAB = 'MERCHANT_AZELA_TAB',
  MERCHANT_AZELA_TAB_EXPORT = 'MERCHANT_AZELA_TAB_EXPORT',
  MERCHANT_BLOCKCHAIN_TAB = 'MERCHANT_BLOCKCHAIN_TAB',

  // ADMINS' PAGE (TABLE)
  ADMINS = 'ADMINS',
  ADMIN_DETAILS = 'ADMIN_DETAILS',

  // LOG' PAGE (TABLE)
  LOG = 'LOG',

  // DOCUMENTS
  DOCUMENTS = 'DOCUMENTS',

  // REPORTS
  MENU = 'MENU',

  REPORTS = 'REPORTS',
  NOTIFICATIONS = 'NOTIFICATIONS',

  REPORTS_USERS = 'REPORTS_USERS',
  REPORTS_CHARITIES = 'REPORTS_CHARITIES',

  ALL_USERS_REPORTS = 'ALL_USERS_REPORTS',
  CARDS_USAGE = 'CARDS_USAGE',
  KYC = 'KYC',
  NEOBANK = 'NEOBANK',
  SIGNUP = 'SIGNUP',
  SDD = 'SDD',
  CLOSED_ACCOUNTS = 'CLOSED_ACCOUNTS',
  PREMIUM_CARDS = 'PREMIUM_CARDS',
  SEND_PUSH = 'SEND_PUSH',
  ACCOUNT_DEPOSIT = 'ACCOUNT_DEPOSIT',
  POT_BALANCE = 'POT_BALANCE',

  CHARITIES_WALLETS = 'CHARITIES_WALLETS',

  // SERVER AVAILABILITY
  SERVICE_AVAILABILITY = 'SERVICE_AVAILABILITY',

  MERCHANT_WALLET_AMOUNT = 'MERCHANT_WALLET_AMOUNT',
  CHARITY_WALLET_AMOUNT = 'CHARITY_WALLET_AMOUNT',
  BUDGET = 'BUDGET',
}
