import { CustomDestroyComponent } from './../../../classes/custom-destroy';
import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { State } from '../../../../reducers';


@Component({
  selector: 'app-footer-cmp',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends CustomDestroyComponent {

  constructor(
    private readonly store: Store<State>
  ) {
    super()
  }

  ngOnInit(): void {}

}
