import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  Self,
} from '@angular/core';
import { filter, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CustomDestroyComponent } from '../../classes/custom-destroy';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent extends CustomDestroyComponent
  implements OnInit {
  pickerForm: FormGroup;

  @Input() required: boolean;
  @Input() name: string;

  @Output() value = new EventEmitter();

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.buildForm();
    this.setValidators();
  }

  ngAfterViewInit(): void {
    this.pickerForm.valueChanges
      .pipe(
        filter((e) => !!e),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => this.value.emit(value));
  }

  buildForm(): void {
    this.pickerForm = this.fb.group({
      value: ['', []],
    });
  }

  setValidators() {
    if (this.required) {
      this.pickerForm.controls['value'].setValidators(Validators.required);
    }
  }
}
